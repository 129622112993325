export default {
  customer: {
    type: [
      { id: 1, title: "Privat" },
      { id: 2, title: "Erhverv" },
    ],
  },
  vehicle: {
    evaluationType: [
      { id: 1, title: "Hvide plader" },
      { id: 2, title: "Gule plader" },
      { id: 3, title: "Fra gul til hvid" },
    ],

    body: [
      {
        id: 1,
        title: "Sedan",
      },
      {
        id: 2,
        title: "Stationcar",
      },
      {
        id: 3,
        title: "Cabriolet",
      },
      {
        id: 4,
        title: "Coupé",
      },
      {
        id: 5,
        title: "SUV",
      },
      {
        id: 6,
        title: "Kassevogn",
      },
    ],
    fuel: [
      {
        id: 1,
        title: "Benzin",
      },
      {
        id: 2,
        title: "Diesel",
      },
      {
        id: 3,
        title: "Hybrid",
      },
      {
        id: 4,
        title: "El",
      },
    ],
    gearbox: [
      {
        id: 1,
        title: "Manuel gear",
      },
      {
        id: 2,
        title: "Automatgear",
      },
    ],
    quality: [
      {
        id: 1,
        title: "Ikke vurderet endnu",
      },
      {
        id: 2,
        title: "Over middel",
      },
      {
        id: 3,
        title: "Middel",
      },
      {
        id: 4,
        title: "Under middel",
      },
    ],
    type: [
      {
        id: 1,
        title: "Personbil",
      },
      {
        id: 2,
        title: "Varebil",
      },
      {
        id: 3,
        title: "Motorcykel",
      },
    ],
    useCase: [
      {
        id: 1,
        title: "Ingen",
      },
      {
        id: 2,
        title: "Taxa",
      },
      {
        id: 3,
        title: "Politi-civilbil",
      },
      {
        id: 4,
        title: "Militærkøretøj",
      },
      {
        id: 5,
        title: "Udrykningskøretøj",
      },
      {
        id: 6,
        title: "Udlejningsbil (ikke leasing)",
      },
    ],
    emissionUnit: [
      {
        id: 1,
        title: "Nedc",
      },
      {
        id: 2,
        title: "Wltp",
      },
      {
        id: 3,
        title: "Nedc2",
      },
    ],
    energyType: [
      {
        id: 1,
        title: "Høj emissionsbil",
      },
      {
        id: 2,
        title: "Lav emissionsbil",
      },
      {
        id: 3,
        title: "Nul emissionsbil",
      },
    ],
  },
};

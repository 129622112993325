import { Form, Input, Select, Upload } from "antd";
import React, { FC } from "react";
import enums from "../enums/enums";
import _t from "../lang/translate";
import { brandsAutoComplete } from "../services/autocompleteService";
import AutoCompleteSelect from "./autoCompleteSelect";
import DateSelect from "./dateSelect";
import EnumRadio from "./enumRadio";
import FilesUpload from "./filesUpload";
import InputNum from "./inputNum";
import InputVin from "./inputVin";

const Vehicle: FC = () => {
  const {
    body,
    useCase,
    gearbox,
    fuel,
    quality,
    evaluationType,
  } = enums.vehicle;

  return (
    <>
      <Form.Item
        name={"evaluationTypeId"}
        label={_t("form.evaluating_type")}
        initialValue={evaluationType[0].id}
      >
        <EnumRadio options={evaluationType} />
      </Form.Item>
      <Form.Item
        rules={[{ required: true }]}
        name="brandId"
        label={_t("car_info.brand")}
      >
        <AutoCompleteSelect apiUrl={brandsAutoComplete()} />
      </Form.Item>
      <Form.Item
        rules={[{ required: true }]}
        name={["vehicle", "model"]}
        label={_t("car_info.model")}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name={["vehicle", "equipmentVariant"]}
        label={_t("car_info.equipmentVariant")}
        rules={[{ required: true }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name={["vehicle", "firstRegDate"]}
        label={_t("car_info.firstRegDate")}
        rules={[{ required: true }]}
      >
        <DateSelect />
      </Form.Item>
      <Form.Item name={["vehicle", "vin"]} label={_t("vin")}>
        <InputVin className="input-group-btn" maxLength={17} />
      </Form.Item>
      <Form.Item
        name={["vehicle", "mileage"]}
        label={_t("mileage")}
        rules={[{ required: true }]}
      >
        <InputNum
          step={1000}
          min={0}
          suffix={<small>{_t("mileage_unit")}</small>}
        />
      </Form.Item>
      <Form.Item
        name={["vehicle", "qualityId"]}
        label={_t("car_info.car_condition")}
        rules={[{ required: true }]}
        initialValue={quality[0].id}
      >
        <EnumRadio options={quality} />
      </Form.Item>
      <Form.Item
        name={["vehicle", "useCaseId"]}
        label={_t("car_info.special_use")}
        rules={[{ required: true }]}
        initialValue={useCase[0].id}
      >
        <EnumRadio options={useCase} />
      </Form.Item>
      <Form.Item
        name={["vehicle", "engineSize"]}
        label={_t("car_info.engine_size")}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name={["vehicle", "fuelId"]}
        label={_t("car_info.fuel_type")}
        initialValue={fuel[0].id}
      >
        <EnumRadio options={fuel} />
      </Form.Item>
      <Form.Item
        name={["vehicle", "bodyId"]}
        label={_t("car_info.trim")}
        initialValue={body[0].id}
      >
        <EnumRadio options={body} />
      </Form.Item>
      <Form.Item
        name={["vehicle", "horsePower"]}
        label={_t("car_info.engine_power")}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name={["vehicle", "gearboxId"]}
        label={_t("car_info.gearbox")}
        initialValue={gearbox[0].id}
      >
        <EnumRadio options={gearbox} />
      </Form.Item>
      <Form.Item name="note" label={_t("note")}>
        <Input.TextArea autoSize={{ maxRows: 4, minRows: 1 }} />
      </Form.Item>
      <Form.Item
        name="files"
        label={_t("tax.label_upload_documents")}
        valuePropName="fileList"
      >
        <FilesUpload />
      </Form.Item>
    </>
  );
};

export default Vehicle;

import { Radio } from "antd";
import React, { FC } from "react";

interface EnumRadioProps {
  options: { [x: string]: any }[];
}

const EnumRadio: FC<EnumRadioProps> = ({ options, ...rest }) => {
  return (
    <Radio.Group optionType="button" buttonStyle="solid" {...rest}>
      {options.map((e) => (
        <Radio.Button key={e.id} value={e.id}>
          {e.title}
        </Radio.Button>
      ))}
    </Radio.Group>
  );
};

export default EnumRadio;

import { Checkbox, Col, Divider, Input, Row, RowProps } from "antd";
import React, { FC } from "react";
import _t from "../lang/translate";
import format from "../utilities/formatNumbers";
interface PaymentProps {
  loading: boolean;
  agreedTerms: boolean;
  pdfURL?: string;
  setAgreedTerms: React.Dispatch<React.SetStateAction<boolean>>;
}
const Payment: FC<PaymentProps> = ({
  loading,
  agreedTerms,
  pdfURL,
  setAgreedTerms,
}) => {
  const rowProps: RowProps = {
    justify: "space-between",
    gutter: [16, 8],
  };
  return (
    <>
      <div
        className="offer-calculation"
        style={{ maxWidth: 380, margin: "0 auto" }}
      >
        <div className="pl-2 pr-2">
          <Row {...rowProps} className="mb-1">
            <Col>{_t("tax.preassesment")}</Col>
            <Col>{format.price(400)}</Col>
          </Row>
          <Row {...rowProps}>
            <Col>{_t("moms")}</Col>
            <Col>{format.price(100)}</Col>
          </Row>
          <Divider className="mt-1 mb-1" />
          <Row {...rowProps}>
            <Col>
              <h3>{_t("total")}</h3>
            </Col>
            <Col>
              <h3>{format.price(500)}</h3>
            </Col>
          </Row>
        </div>
      </div>
      <Divider />
      <Checkbox
        disabled={loading}
        checked={agreedTerms}
        onChange={({ target }) => setAgreedTerms(target.checked)}
      >
        {_t("tax.msg_accept_terms")}&nbsp;
        <a href={pdfURL} target="_blank">
          {_t("tax.msg_accept_terms_link")}
        </a>
      </Checkbox>
      <Divider />
    </>
  );
};

export default Payment;

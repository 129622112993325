import appConfig from "../appConfig";

const consts = {
  formTailLayout: {
    // used in config values
    label: { xs: 24, lg: 10 },
    field: { xs: 24, lg: 14 },
  },
  formInlineLayout: {
    labelCol: { lg: 6, xs: 24 },
    wrapperCol: { lg: 18, xs: 24 },
    colon: false,
  },
  formInlineTailLayout: {
    wrapperCol: { lg: { offset: 6, span: 18 }, xs: { offset: 0, span: 24 } },
  },
  timeFormat: "HH:mm",
  dateFormats: [
    appConfig("dateFormat"),
    "DDMMYYYY",
    "DDMMYY",
    "DD-MM-YY",
    "D-MM-YYYY",
    "DD-M-YYYY",
    "DD/MM/YYYY",
    "D/MM/YYYY",
    "DD/M/YYYY",
    "D/M/YYYY",
  ],
};
export default consts;

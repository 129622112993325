import { Button, message, Select, Spin } from "antd";
import { SelectProps } from "antd/lib/select";
import React, { FC, useEffect, useState } from "react";
import _t from "../lang/translate";
import { useApi } from "../services/useApi";
import { PaginationedData } from "../types/apiTypes";
import { EnumItemType, IdType } from "../types/appTypes";
import AlertMessage from "./alertMessage";

const { Option } = Select;

interface AutoCompleteSelectProps extends SelectProps<[]> {
  apiUrl: string;
  initTitle?: string | null;
  dark?: boolean;
  value?: any;
  filters?: string;
}

const AutoCompleteSelect: FC<AutoCompleteSelectProps> = ({
  apiUrl,
  value,
  initTitle,
  dark,
  dropdownClassName,
  mode,
  filters = "",
  ...rest
}) => {
  const [{ data: response, isLoading, isError }, setUrl] = useApi<
    PaginationedData<EnumItemType>
  >("", { data: [] });

  const [filterLocally, setFilterLocally] = useState<boolean | undefined>(
    undefined
  );
  const [searchValue, setSearchValue] = useState<string>("");

  const { data, meta } = response;

  useEffect(() => {
    handleSearch("", true);
  }, [filters]);

  useEffect(() => {
    handleSearch("");
  }, [value]);

  const handleSearch = (v: string, initSearch?: boolean) => {
    setSearchValue(v);
    let fl = undefined;
    if (!initSearch) {
      fl =
        typeof filterLocally === "undefined" && meta
          ? meta?.current_page === meta?.last_page
          : filterLocally;
    }
    setFilterLocally(fl);

    if (fl) return;

    let searchTerm = v;

    if (
      !initSearch &&
      typeof value === "number" &&
      data.length &&
      !data.some((item) => item.id === value)
    ) {
      searchTerm = value.toString();
    }

    const query = new URLSearchParams(filters);
    query.set("filter[q]", searchTerm);
    setUrl(apiUrl + query.toString());
  };

  return (
    <>
      <Select
        {...rest}
        loading={isLoading}
        showSearch
        mode={mode}
        dropdownClassName={`${
          dark ? "dropdown-dark " : ""
        }${dropdownClassName}`}
        defaultActiveFirstOption={true}
        onSearch={handleSearch}
        searchValue={searchValue}
        filterOption={
          filterLocally
            ? (input, option) =>
                option?.label
                  ? option.label
                      .toString()
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  : false
            : false
        }
        value={value === 0 ? null : value}
        dropdownRender={(menu) => <>{menu}</>}
      >
        {isLoading ? (
          <Option
            key="init"
            value={-10}
            className="text-center"
            disabled={true}
          >
            <Spin spinning />
          </Option>
        ) : (
          data?.map((item) => (
            <Option key={item.id} value={item.id} label={item.title}>
              {item.title}
            </Option>
          ))
        )}
      </Select>
      {isError && (
        <AlertMessage
          message={_t("msg.unkonwn_error")}
          className="mt-1"
          type="error"
          closable
        />
      )}
    </>
  );
};

export default AutoCompleteSelect;

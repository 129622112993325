import axios from "axios";
import _t from "../lang/translate";

export default {
  get: axios.get,
  post: axios.post,
  delete: axios.delete,
  put: axios.put,
  patch: axios.patch,
  headers: axios.defaults.headers,
};

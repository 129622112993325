import dayjs from "dayjs";

const local = "da-DK";

interface NumberformatProps {
  style?: "decimal" | "currency" | "percent" | "unit" | undefined;
  unit?: "kilometer" | "kilogram" | undefined;
  unitDisplay?: "long" | "short" | "narrow" | undefined;
}

type NumFunc = (number: number, props?: NumberformatProps) => string;

const number: NumFunc = (number, props) =>
  new Intl.NumberFormat(local, props).format(number);

const percent: NumFunc = (number, props) =>
  new Intl.NumberFormat(local, {
    style: "percent",
    maximumFractionDigits: 2,
    ...props,
  }).format(number);

type PriceFunc = (
  number: number | null | undefined,
  maximumFractionDigits?: number,
  currency?: string
) => string;

const price: PriceFunc = (
  number,
  maximumFractionDigits = 0,
  currency = "DKK"
) => {
  if (!number) return "";
  let config: Intl.NumberFormatOptions = {
    style: "currency",
    currency,
  };
  if (maximumFractionDigits > 0) config = { ...config, maximumFractionDigits };
  else number = Math.round(number);
  return new Intl.NumberFormat(local, config).format(number).replace(",00", "");
};

const date = (value: string | null) =>
  value !== null ? dayjs(value).format("DD/MM/YYYY") : "";

const milage = (value: number | null) =>
  value !== null ? number(value, { style: "unit", unit: "kilometer" }) : "";

const time = (value: string | null, prefix?: boolean) =>
  value != null ? `${prefix ? "kl. " : ""}${dayjs(value).format("HH:mm")}` : "";

const dateAndTime = (value: string | null) =>
  value !== null ? `${date(value)} - ${time(value, true)}` : "";

const format = { price, number, percent, date, time, dateAndTime, milage };

export default format;

import React, { FC } from "react";
import { Button, Result } from "antd";
import _t from "./lang/translate";
import { useHistory } from "react-router-dom";

interface NotFoundProps {
  title?: string;
}

const NotFound: FC<NotFoundProps> = ({ title = _t("msg.not_found") }) => {
  const history = useHistory();
  return (
    <>
      <Result
        status="404"
        title={title}
        subTitle={_t("msg.not_found_describtion")}
        /*         extra={
          <Button onClick={() => history.goBack()}>{_t("btn.go_back")}</Button>
        } */
      />
    </>
  );
};

export default NotFound;

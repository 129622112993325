import { Col, Form, Input, Radio, Row } from "antd";

import React, { FC } from "react";
import enums from "../enums/enums";
import _t from "../lang/translate";
import consts from "../utilities/consts";
import EnumRadio from "./enumRadio";

const Customer: FC = () => {
  const { type } = enums.customer;
  return (
    <>
      <Form.Item
        name={["customer", "typeId"]}
        initialValue={type[0].id}
        {...consts.formInlineTailLayout}
      >
        <EnumRadio options={type} />
      </Form.Item>
      <Form.Item
        shouldUpdate={(curr, next) =>
          curr?.customer?.typeId !== next?.customer?.typeId
        }
        noStyle
      >
        {({ getFieldValue }) => {
          const isPrivateCustomer =
            getFieldValue(["customer", "typeId"]) === type[0].id;
          return (
            <>
              {isPrivateCustomer ? (
                <>
                  <Form.Item
                    name={["customer", "nameFirst"]}
                    rules={[{ required: true }]}
                    label={_t("firstname")}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    name={["customer", "nameLast"]}
                    label={_t("lastname")}
                  >
                    <Input />
                  </Form.Item>
                </>
              ) : (
                <Form.Item name={["customer", "name"]} label={_t("name")}>
                  <Input />
                </Form.Item>
              )}
            </>
          );
        }}
      </Form.Item>
      <Form.Item
        name={["customer", "email"]}
        rules={[{ required: true }, { type: "email" }]}
        label={_t("email")}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name={["customer", "phone"]}
        rules={[{ required: true }]}
        label={_t("phone")}
      >
        <Input />
      </Form.Item>
      <Form.Item name={["customer", "address"]} label={_t("address")}>
        <Input />
      </Form.Item>
      <Form.Item {...consts.formInlineTailLayout}>
        <Row gutter={16} className="flex-wrap">
          <Col flex="120px">
            <Form.Item name={["customer", "zip"]} noStyle>
              <Input placeholder={_t("zip")} />
            </Form.Item>
          </Col>
          <Col flex="auto">
            <Form.Item name={["customer", "city"]} noStyle>
              <Input placeholder={_t("city")} />
            </Form.Item>
          </Col>
        </Row>
      </Form.Item>
    </>
  );
};

export default Customer;

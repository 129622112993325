import * as React from "react";
import { IconNames } from "./icon-list";

interface IconProps extends React.SVGProps<SVGSVGElement> {
  name: IconNames;
  className?: string;
  size?: "small" | "medium" | "large" | "huge";
}

const Icon: React.FC<IconProps> = ({
  name,
  className,
  size,
  ...rest
}): JSX.Element | null => {
  const ImportedIconRef = React.useRef<
    React.FC<React.SVGProps<SVGSVGElement>> | any
  >();
  const [loading, setLoading] = React.useState(false);
  React.useEffect(() => {
    let didCancel = false;
    const importIcon = async (): Promise<void> => {
      if (!didCancel) setLoading(true);
      try {
        ImportedIconRef.current = (
          await import(
            `!!@svgr/webpack?-svgo,+titleProp,+ref!./svg-icons/${name}.svg`
          )
        ).default;
      } catch (err) {
        throw err;
      } finally {
        if (!didCancel) setLoading(false);
      }
    };
    importIcon();
    return () => {
      didCancel = true;
    };
  }, [name]);

  const btnClass = { ...rest }.onClick ? "btn-icon" : "";

  if (!loading && ImportedIconRef.current) {
    const { current: ImportedIcon } = ImportedIconRef;
    return (
      <span
        className={`anticon ${name}-icon ${
          size ? "icon-size-" + size : ""
        } ${btnClass}`}
        role="img"
      >
        <ImportedIcon
          width="1em"
          height="1em"
          fill="currentColor"
          className={`${className ? className : ""}`}
          {...rest}
        />
      </span>
    );
  }
  return null;
};

export default Icon;

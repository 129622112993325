import React, { FC } from "react";

interface StepContentProps {
  show: boolean;
}

const StepContent: FC<StepContentProps> = ({ show, children }) => {
  return <div style={{ display: show ? "block" : "none" }}>{children}</div>;
};

export default StepContent;

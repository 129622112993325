import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import ReactDOM from "react-dom";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { ConfigProvider, Layout } from "antd";
import DaDK from "antd/es/locale/da_DK";
import { BrowserRouter } from "react-router-dom";
import { validateMessages } from "./formMessages";

//dayjs.locale("da");

ReactDOM.render(
  <ConfigProvider locale={DaDK} form={{ validateMessages }}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </ConfigProvider>,
  document.getElementById("root")
);

serviceWorker.unregister();

export const getApiHost = () => process.env.REACT_APP_API_HOST;

const getApiEndpoint = () => {
  const protocol = process.env.REACT_APP_API_PROTOCOL;
  const version = process.env.REACT_APP_API_VERSION;
  const host = process.env.REACT_APP_API_HOST;
  const apiEndpoint = process.env.REACT_APP_API_ENDPOINT;

  if (!protocol || !host || !version) {
    throw Error("Please check api endpoint variables in .env file.");
  }
  return `${protocol}://${host}${apiEndpoint}/${version}`;
};

export default getApiEndpoint;

import { message, Upload } from "antd";
import { DraggerProps, UploadChangeParam } from "antd/lib/upload";
import { UploadRequestOption } from "rc-upload/lib/interface";
import { UploadFile } from "antd/lib/upload/interface";
import React, { FC } from "react";
import _t from "../lang/translate";
import { accepted } from "../utilities/acceptedFiles";
import Icon from "../assets/icons/icon";

interface FilesUploadProps extends Omit<DraggerProps, "onChange"> {
  onChange?: (fileList: UploadFile[]) => void;
}

const FilesUpload: FC<FilesUploadProps> = ({ onChange, fileList }) => {
  const handleChange = (info: UploadChangeParam<UploadFile<any>>) => {
    onChange && onChange(info.fileList);
  };
  return (
    <Upload.Dragger
      multiple
      accept={accepted.join(", ")}
      customRequest={dummyRequest}
      onChange={handleChange}
      //beforeUpload={beforeUpload}
      fileList={fileList}
    >
      <p>
        <Icon name="cloud-upload-outline" />
        {_t("btn.upload")}
      </p>
      <small>{_t("form.upload_placeholder")}</small>
    </Upload.Dragger>
  );
};

export default FilesUpload;

function beforeUpload(file: Blob) {
  const isLt2M = file.size / 1024 / 1024 < 2;
  if (!isLt2M) {
    message.error("Image must smaller than 2MB!");
  }
  return isLt2M;
}

function dummyRequest({ file, onSuccess, onError }: UploadRequestOption) {
  setTimeout(() => {
    const acceptedFile = beforeUpload(file);
    let reader = new FileReader();
    reader.readAsDataURL(file);
    if (!acceptedFile) {
      onError && onError(new ProgressEvent("error"));
      reader.abort();
    }

    reader.onload = () => {
      onSuccess && onSuccess(reader.result, new XMLHttpRequest());
    };
    reader.onerror = (e) => {
      onError && onError(e);
    };
  }, 0);
}

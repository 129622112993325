import { Input } from "antd";
import { InputProps } from "antd/lib/input";
import React, { FC } from "react";

interface InputCVRProps extends InputProps {
  value?: string;
}

const InputVin: FC<InputCVRProps> = ({ value, ...rest }) => {
  return <Input value={value?.toUpperCase()} maxLength={8} {...rest} />;
};

export default InputVin;

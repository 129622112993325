import React, { FC, useState } from "react";
import { Button, Col, Form, message, Row, Steps } from "antd";
import Customer from "./customer";
import Payment from "./payment";
import Vehicle from "./vehicle";
import consts from "../utilities/consts";
import _t from "../lang/translate";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import StepContent from "./stepContent";
import { createTaxCase } from "../services/taxService";
import { TaxCreationType } from "../types/taxTypes";
import { useParams } from "react-router-dom";
import { LinkType, ParamTypes } from "../types/appTypes";
import Icon from "../assets/icons/icon";

const { Step } = Steps;
interface CreateTaxCaseProps {
  pdfURL?: string;
}
const CreateTaxCase: FC<CreateTaxCaseProps> = ({ pdfURL }) => {
  const { id } = useParams<ParamTypes>();
  const [currStep, setCurrStep] = useState<number>(0);
  const [agreedTerms, setAgreedTerms] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [taxForm] = Form.useForm<TaxCreationType>();

  const handleStepChange = (key: number) => {
    if (key === 2) {
      taxForm
        .validateFields()
        .then(() => setCurrStep(key))
        .catch((error) => {
          const firstErrorField = error.errorFields[0].name;
          const key = Array.isArray(firstErrorField)
            ? firstErrorField[0]
            : firstErrorField;
          if (key === "customer") setCurrStep(0);
          else if (key === "vehicle") setCurrStep(1);
        });
    } else setCurrStep(key);
  };
  const inCustomer = currStep === 0,
    inVehicle = currStep === 1,
    inPayment = currStep === 2;

  const handleFormFinish = async (values: TaxCreationType) => {
    let files = [...(values.files || [])].filter(
      (file) => file.status === "done" && file.response
    );
    files = files.map(({ uid, name, response, type, size }) => ({
      uid,
      name,
      url: response,
      size,
      type,
    }));
    try {
      setLoading(true);
      const { data } = await createTaxCase<{ data: LinkType }>(id, {
        ...values,
        files,
      });
      data.data.external && window.open(data.data.external, "_self");
    } catch ({ response }) {
      message.error(response?.data?.message || _t("msg.unkonwn_error"));
    } finally {
      setLoading(false);
    }
  };
  const paymentProps = {
    loading,
    agreedTerms,
    pdfURL,
    setAgreedTerms,
  };
  return (
    <>
      <div className="card content-bg p-3">
        <h1 className="card-title text-center mb-2">
          Hvilket køretøj ønsker du en afgiftsberegning på?
        </h1>
        <Form
          form={taxForm}
          {...consts.formInlineLayout}
          onFinish={handleFormFinish}
        >
          <div className="mb-4">
            <Steps size="small" current={currStep} onChange={handleStepChange}>
              <Step title="Din Info" />
              <Step title="Køretøj" />
              <Step title="Betaling" />
            </Steps>
          </div>
          <StepContent show={inCustomer}>
            <Customer />
          </StepContent>
          <StepContent show={inVehicle}>
            <Vehicle />
          </StepContent>
          <StepContent show={inPayment}>
            <Payment {...paymentProps} />
          </StepContent>
        </Form>
        <Row justify="space-between" className="pt-2">
          <Col>
            {
              //// PREV STEP
              !inCustomer && (
                <Button
                  type="ghost"
                  disabled={loading}
                  onClick={() => handleStepChange(currStep - 1)}
                >
                  <LeftOutlined />
                  {inVehicle && _t("customer")}
                  {inPayment && _t("vehicle")}
                </Button>
              )
            }
          </Col>
          <Col>
            {
              //// NEXT STEP
              <Button
                type="primary"
                loading={loading}
                disabled={inPayment && !agreedTerms}
                onClick={() =>
                  inPayment ? taxForm.submit() : handleStepChange(currStep + 1)
                }
              >
                {inCustomer && (
                  <>
                    {_t("vehicle")}&nbsp;
                    <RightOutlined />
                  </>
                )}
                {inVehicle && (
                  <>
                    {_t("payment")}&nbsp;
                    <RightOutlined />
                  </>
                )}
                {inPayment && (
                  <>
                    Gå til betaling&nbsp;
                    <Icon name="external-link" />
                  </>
                )}
              </Button>
            }
          </Col>
        </Row>
      </div>
    </>
  );
};

export default CreateTaxCase;

import http from "./httpService";
import getApiEndpoint from "./apiEndpoint";

const apiEndpoint = `${getApiEndpoint()}/taxCaseCreationRequest`;

export function createTaxCase<T>(id: string, data: any) {
  return http.post<T>(`${apiEndpoint}/${id}`, data);
}

export function checkServerId(id: string) {
  return http.get(`${apiEndpoint}/${id}`);
}

import { Layout } from "antd";
import React, { FC } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import appConfig from "./appConfig";
import "./assets/style/app.less";
import Logo from "./layout/logo";
import NotFound from "./notFound";
import TaxCase from "./taxCase";

const { Content, Footer } = Layout;

const App: FC = () => {
  return (
    <Layout className="layout">
      <Content className="content">
        <Logo />
        <Switch>
          <Route path="/404">
            <NotFound />
          </Route>
          <Route path="/:id/:type?" exact>
            <TaxCase />
          </Route>
          <Redirect from="/" to="/404" />
        </Switch>
      </Content>
      <Footer className="text-center">
        {appConfig("companyName")} © {new Date().getFullYear()}
      </Footer>
    </Layout>
  );
};

export default App;

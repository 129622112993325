import DatePicker, { DatePickerProps } from "antd/lib/date-picker";
import moment, { Moment } from "moment";
import React, { FC } from "react";
import consts from "../utilities/consts";

type DateSelectProps = DatePickerProps & {
  value?: string;
  dark?: boolean;
  formate?: any[];
  onChange?: (value: string) => void;
};

const DateSelect: FC<DateSelectProps> = ({
  value,
  onChange,
  dark,
  dropdownClassName,
  formate,
  ...rest
}) => {
  const handleChange = (date: Moment | null) => {
    if (onChange && date !== null) onChange(date.toISOString());
  };
  return (
    <DatePicker
      dropdownClassName={`${dark ? "dark-picker " : ""}${dropdownClassName}`}
      value={value && moment(value)}
      onChange={handleChange}
      format={formate || consts.dateFormats}
      {...rest}
    />
  );
};

export default DateSelect;

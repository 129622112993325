import { Button, Result, Spin } from "antd";
import React, { FC, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";
import appConfig from "./appConfig";
import CreateTaxCase from "./components/createTaxCase";
import _t from "./lang/translate";
import NotFound from "./notFound";
import { checkServerId } from "./services/taxService";
import { ParamTypes } from "./types/appTypes";

const TaxCase: FC = () => {
  const { id, type } = useParams<ParamTypes>();
  const history = useHistory();
  const [loading, setLoading] = useState<boolean>(false);
  const [validId, setValidId] = useState<boolean>(false);
  const [pdfURL, setPdfURL] = useState<string>("");

  useEffect(() => {
    async function checkId() {
      try {
        setLoading(true);
        const { data } = await checkServerId(id);
        setPdfURL(data.terms);
        setValidId(true);
      } catch (error) {
        setValidId(false);
      } finally {
        setLoading(false);
      }
    }
    checkId();
  }, []);

  if (loading)
    return (
      <div className="text-center m-3">
        <Spin size="large" />
      </div>
    );

  if (type === "ok")
    return (
      <Result
        status="success"
        title="Tak for din betaling!"
        subTitle="vi har optaget din ordre, du bliver opdateret på din E-mail"
      />
    );

  if (type === "fejl")
    return (
      <Result
        status="error"
        title="Der er sket en fejl i din betaling!"
        subTitle={`Prøv igen senere! hvis du få det sammen fejl, kontakt os på: ${appConfig(
          "companyPhone"
        )}`}
        extra={
          <Button
            onClick={() => {
              history.push(history.location.pathname.replace("/fejl", ""));
            }}
          >
            {_t("btn.go_back")}
          </Button>
        }
      />
    );

  if (validId) return <CreateTaxCase pdfURL={pdfURL} />;

  return <NotFound />;
};

export default TaxCase;

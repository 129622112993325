import Alert, { AlertProps } from "antd/lib/alert";
import React, { FC } from "react";

interface AlertMessageProps extends AlertProps {
  raw?: boolean;
  borderd?: boolean;
}

const AlertMessage: FC<AlertMessageProps> = ({
  raw,
  borderd,
  className,
  ...rest
}) => {
  const classes = borderd ? "borderd" : className;
  return <Alert {...rest} className={raw ? "raw" + className : classes} />;
};

export default AlertMessage;

/* import { getLocal } from "../services/login/authService";
const local = getLocal(); */

import danish from "./da_DK.json";

interface StringMap {
  [key: string]: string;
}
type TranslateFunction = (
  term: termType,
  subterm?: termType,
  linking?: string
) => string;

type termType = keyof typeof danish;

const _t: TranslateFunction = (term, subterm, linking = " ") => {
  const da: StringMap = danish;
  const getTerm = da[term] || "";
  const getSubterm = subterm ? da[subterm].toLowerCase() || "" : "";
  return getSubterm.length > 0 ? `${getTerm}${linking}${getSubterm}` : getTerm;
};

export default _t;
